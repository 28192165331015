import quotation from '@/store/quotation'
import { mapState, mapActions } from 'vuex'

export default {

  data() {
    return {
      showRuleMessage:true
    }
  },
  computed: {
    ...mapState('quotation',['quoteInformationForm']),
    isOneOfDriver(){
      let {policy_holder_is_driver,client_type} = this.quoteInformationForm||{}
      return policy_holder_is_driver ==1&&client_type==1
    },
    ruleType(){
      return type =>{
        let obj ={
          1:'input',
          2:'select',
          3:'select',
          4:'input',
        }
        let item = obj[type]
        return this.rules[item]
      }
    },
    policyForm(){
      let {coverage={},vehicle_detail={},client_type,is_policyholder} = this.formData||{}
      return [
        {
          en: 'Client Type',
          zh: '客戶類型',
          key: 'client_type',
          list:this.personTypeist,
          type:23,
          val:this.getOptionName('personTypeist',this.formData.client_type)
        },
        {
          en: 'Are you the vehicle owner?',
          zh: '閣下是否車主本人？',
          key: 'is_policyholder',
          list:this.yesOrNoOption,
          type:23,
          noshow: client_type==2,
          val:this.getOptionName('yesOrNoOption',this.formData.is_policyholder)
        },
        {
          en: 'Authorised Person Name',
          zh: '代辦人名稱',
          key: 'agent_name',
          noshow: client_type==2||is_policyholder==1,
          val:this.formData.agent_name
        },
        {
          en: 'Authorised Person Mobile Number',
          zh: '代辦人手機號碼',
          key: 'agent_mobile_no',
          noshow: client_type==2||is_policyholder==1,
          val:this.formData.agent_mobile_no
        },
        {
          en: 'Policy Type',
          zh: '保單類型',
          key: 'policy_type',
          list:this.globalOptions.client_policy_type,
          type:23,
          val:this.getOptionName('globalOptions.client_policy_type',this.formData.policy_type)
        },
        {
          en: 'Policy Effective Date',
          zh: '保單生效日期',
          key: 'effective_date',
          type: 4,
          val:this.formData.effective_date
        },
        // {
        //   en: 'Coverage',
        //   zh: '保障類型',
        //   key:'coverage_type',
        //   val:this.getOptionName('globalOptions.coverage_type',coverage.cover_type)
        // },
        ...this.driverFormList1,
        {
          en: 'NCD',
          zh: '無索償折扣',
          type: 2,
          list: this.ncdOption(1003),
          key:'ncd',
          val:this.getOptionName('',vehicle_detail.ncd,this.ncdOption(1003))

        },
        // {
        //   en: 'Previous Insurer',
        //   zh: '前保險公司',
        //   type: 2,
        //   list: this.globalOptions.previous_insurance_company,
        //   key:'previous_insurance_company_id',
        //   noshow:vehicle_detail.ncd==0,
        //   val:this.getOptionName('globalOptions.previous_insurance_company',vehicle_detail.previous_insurance_company_id)
        // },
        {
          en: 'Is the subject Insured vehicle be the same as the one under the previous policy?',
          zh: '現投保車輛是否與此保單受保車輛為同一車輛？',
          type: 3,
          list: this.yesOrNoOption,
          key:'is_same_previous_car',
          noshow:vehicle_detail.ncd==0,
          val:this.getOptionName('yesOrNoOption',vehicle_detail.is_same_previous_car)

        },
        {
          en: 'Previous Policy Number',
          zh: '前保單號碼',
          type: 1,
          key:'policy_number',
          noshow:vehicle_detail.ncd==0,
          val:vehicle_detail.policy_number

        },
        {
          en: 'Previous Policy Registration Mark',
          zh: '前車輛登記號碼',
          type: 1,
          key:'registration_number',
          noshow:vehicle_detail.ncd==0,
          val:vehicle_detail.registration_number
        },
      ]
    },
    motorFormList() {
      //type 1:input 2:select 3 radio
      let {coverage={},vehicle_detail={}} = this.formData||{}
      return [
        {
          en: 'Registration Mark',
          zh: '車輛登記號碼',
          en_content: 'If you will change the Registration Mark, please input ***',
          zh_content: '如改車牌請填***',
          type: 1,
          // isRule: false,
          key:'registration_mark',
          val:vehicle_detail.registration_mark
        },
        {
          en: 'Make',
          zh: '車廠',
          type: 2,
          list: this.carMakeOption(1003),
          key:'car_make_id',
          val:this.getOptionName('',vehicle_detail.car_make_id,this.carMakeOption(1003))

        },
        {
          en: 'Model',
          zh: '型號',
          type: 1,
          key:'car_model',
          val:vehicle_detail.car_model
        },
        {
          en: 'Body Type',
          zh: '車身類型',
          type: 3,
          list: this.bodyTypeOption(1003),
          key:'body_type_id',
          labelKey:'name1',
          val:this.getOptionName('',vehicle_detail.body_type_id,this.bodyTypeOption(1003))

        },
        {
          en: 'Year of Manufacture',
          zh: '出廠年份',
          type: 2,
          list: this.yearOption(1003),
          key:'year_of_manufacture',
          val:this.getOptionName('',vehicle_detail.year_of_manufacture,this.yearOption(1003))

        },
        {
          en: vehicle_detail.body_type_id==43?'Power':'Cylinder Capacity',
          zh: vehicle_detail.body_type_id==43?'動能':'汽缸容量',
          type: 1,
          append: vehicle_detail.body_type_id==43?'kW':'cc',
          keyNumber:vehicle_detail.body_type_id==43?false:true,
          // max:vehicle_detail.body_type_id==21?650:999,
          keyType:vehicle_detail.body_type_id==43?'text':'number',
          keyFloatNumber:vehicle_detail.body_type_id==43,
          key:'vehicle_cylinder_capacity',
          val:vehicle_detail.vehicle_cylinder_capacity + `${vehicle_detail.body_type_id==43?' kW':'cc'}`
        },
        {
          en: 'Seating Capacity (excl. driver)',
          zh: '乘客座位數量（除司機外）',
          type: 2,
          list: this.seatingCapOption(1003),
          key:'searing_capacity',
          keyType:'number',
          val:this.getOptionName('',vehicle_detail.searing_capacity,this.seatingCapOption(1003))
        },
        // {
        //   en: 'Chassis Number',
        //   zh: '車身底盤號碼',
        //   type: 1,
        //   key:'chassis_number',
        //   val:vehicle_detail.chassis_number
        // },
        // {
        //   en: 'Engine Number',
        //   zh: '引擎號碼',
        //   type: 1,
        //   key:'engine_number',
        //   noshow:vehicle_detail.body_type_id==43,
        //   val:vehicle_detail.engine_number
        // },

        {
          en: 'Is there any modification in the above vehicle?',
          zh: '以上車輛是否有任何改裝？',
          type: 3,
          list: this.modificationOption,
          key:'is_modification',
          val:this.getOptionName('modificationOption',vehicle_detail.is_modification)

        },
        {
          en: 'Is the vehicle fitted with Car Camcorder?',
          zh: '車輛是否已安裝行車紀錄儀？',
          type: 3,
          list: this.camcorderOption,
          key:'is_install_camcorder',
          val:this.getOptionName('camcorderOption',vehicle_detail.is_install_camcorder)

        },
      ]
    },
    policyholderFormList() {
      //type 1:input 2:select 3 radio 4.date
      return [
        {
          en: 'Title',
          zh: '稱謂',
          type: 3,
          list: this.titleOption,
          key:'policy_holder_title',
          val:this.getOptionName('titleOption',this.formData.policy_holder_title)

        },
        {
          en: 'Last Name',
          zh: '姓氏',
          type: 1,
          showMessage: false,
          key:'policy_holder_last_name',
          val:this.formData.policy_holder_last_name
        },
        {
          en: 'First Name',
          zh: '名字',
          type: 1,
          key:'policy_holder_first_name',
          val:this.formData.policy_holder_first_name
        },
        {
          en: 'Date of Birth',
          zh: '出生日期',
          type: 4,
          key:'policy_holder_birth',
          val:this.formData.policy_holder_birth
        },
        {
          en: this.formData.policy_holder_id_type==1?'HKID Number':'Passport Number',
          zh: this.formData.policy_holder_id_type==1?'香港身份證號碼':'護照號碼',
          // en_tip: 'If the HKID Number is A123456(3), please input A1234563.\n',
          // zh_tip: '如香港身份證號碼為 A123456(3)，只須輸入 A1234563。',
          type: 5,
          key:this.formData.policy_holder_id_type==1?'policy_holder_id_no':'policy_holder_pp_no',
          showMessage:true,
          val:this.formData.policy_holder_id_type==1?this.formData.policy_holder_id_no:this.formData.policy_holder_pp_no
        },
        {
          en: 'Mobile Number (WhatsApp)',
          zh: '手機號碼 (WhatsApp)',
          // en_content: 'Can receive Whatsapp',
          // zh_content: '接收whatsapp',
          type: 1,
          preappend: '+852',
          key:'policy_holder_mobile',
          keyType:'tel',
          maxlength:8,
          keyNumber:true,
          val:this.formData.policy_holder_mobile
        },
        {
          en: 'Email',
          zh: '電郵',
          en_content: 'Optional',
          zh_content: '非必填',
          type: 1,
          key:'policy_holder_email',
          isUpper:false,
          isLow:true,
          val:this.formData.policy_holder_email
        },
        {
          en: 'Occupation',
          zh: '職業',
          type: 2,
          list: this.globalOptions.occ,
          key:'policy_holder_occ_id',
          labelKey:'name1',
          val:`${this.getOptionName('globalOptions.occ',this.formData.policy_holder_occ_id)} ${this.formData.policy_holder_occ_id==70?this.formData.policy_holder_occ:''}`
        },
        {
          en: 'Are you the driver of this car?',
          zh: '閣下是否此車的駕駛人？',
          type: 3,
          list: this.yesOrNoOption,
          key: 'policy_holder_is_driver',
          val:this.getOptionName('yesOrNoOption',this.formData.policy_holder_is_driver)
        },
        {
          en: 'Will the vehicle be used for food delivery purpose?',
          zh: '車輛會否用作餐飲外送(外賣)用途？',
          type: 3,
          list: this.deliveryOption,
          key:'is_delivery_purpose',
          noshow: this.formData.policy_holder_occ_id!=17,
          val:this.getOptionName('deliveryOption',this.formData.is_delivery_purpose)
        },
      ]
    },
    policyholderList(){
      return [
        {
          en: 'Client Type',
          zh: '客戶類型',
          type: 3,
          list:this.personTypeist,
          key:'client_type',
          val:this.getOptionName('personTypeist',this.formData.client_type)

        },
      ]
    },
    policyholderFormCompanyList() {
      //type 1:input 2:select 3 radio 4.date
      return [
        {
          en: 'Company Name',
          zh: '公司名稱',
          type: 1,
          key:'holder_company_name',
          val:this.formData.holder_company_name

        },
        {
          en: 'CI Number',
          zh: '公司註冊證書號碼',
          type: 1,
          key:'holder_company_id_no',
          val:this.formData.holder_company_id_no
        },
        {
          en: 'Company Phone Number',
          zh: '公司電話號碼',
          type: 1,
          preappend: '+852',
          key:'holder_company_phone',
          keyType:'tel',
          maxlength:8,
          keyNumber:true,
          val:this.formData.holder_company_phone
        },
        {
          en: 'Company Email',
          zh: '公司電郵',
          en_content: 'Optional',
          zh_content: '非必填',
          type: 1,
          isUpper:false,
          isLow:true,
          key:'holder_company_email',
          val:this.formData.holder_company_email
        },
        {
          en: 'Nature of Business',
          zh: '業務性質',
          type: 2,
          list:this.globalOptions.industry,
          key:'holder_company_nature_id',
          labelKey:'name1',
          showMessage:true,
          val:`${this.getOptionName('globalOptions.industry',this.formData.holder_company_nature_id)} ${this.formData.holder_company_nature_id==48?this.formData.holder_company_nature:''}`
        },
        {
          en: 'Will the vehicle be used for food delivery purpose?',
          zh: '車輛會否用作餐飲外送(外賣)用途？',
          type: 3,
          list: this.deliveryOption,
          key:'is_delivery_purpose',
          noshow: this.formData.holder_company_nature_id!=14,
          val:this.getOptionName('deliveryOption',this.formData.is_delivery_purpose)
        },
      ]
    },
    policyholderAddressFormList() {
      let {correspondence_address={}} = this.formData||{}
      return [
        {
          en: 'Room',
          zh: '室',
          type: 1,
          isFlex: true,
          key:'room',
          val:correspondence_address.room
        },
        {
          en: 'Floor',
          zh: '樓',
          type: 1,
          append: '/F',
          isFlex: true,
          key:'floor',
          val:correspondence_address.floor
        },
        {
          en: 'Block',
          zh: '座',
          type: 1,
          isFlex: true,
          key:'block',
          val:correspondence_address.block
        },
      ]
    },
    policyholderAddressFormList1() {
      let {correspondence_address={}} = this.formData||{}

      return [
        {
          en: 'Building / Estate',
          zh: '大廈/屋苑名稱',
          type: 1,
          key:'building',
          val:correspondence_address.building
        },
        {
          en: 'Street',
          zh: '街名',
          type: 1,
          key:'street',
          val:correspondence_address.street
        },
        {
          en: 'District',
          zh: '區域',
          type: 2,
          list: this.globalOptions.area.district,
          key:'district',
          labelKey:'name1',
          val:this.getOptionName('globalOptions.area.district',correspondence_address.district)
        },
        {
          en: 'Area',
          zh: '地域',
          type: 2,
          list: this.globalOptions.area.area,
          key:'area',
          labelKey:'name1',
          val:this.getOptionName('globalOptions.area.area',correspondence_address.area)
        },
      ]
    },
    policyholderCompanyAccountFormList() {
      let {correspondence_address={}} = this.formData||{}

      return [
        {
          en: 'Contact Person Name',
          zh: '聯絡人名字',
          type: 1,
          key:'holder_contact_name',
          val:this.formData.holder_contact_name
        },
        {
          en: 'Mobile Number (WhatsApp)',
          zh: '手機號碼 (WhatsApp)',
          // en_content: 'Can receive Whatsapp',
          // zh_content: '接收whatsapp',
          type: 1,
          preappend: '+852',
          keyType:'tel',
          keyNumber:true,
          maxlength:8,
          key:'holder_contact_phone',
          val:this.formData.holder_contact_phone
        },
      ]
    },
    driverFormList1() {
      //type 1:input 2:select 3 radio 4.date
      let {policy_holder_is_driver} = this.formData||{}
      let driverList = this.formData.driver||[]
      let driver = driverList[0]||{}
      return [
        // {
        //   en: 'Policyholder is one of the Named Driver ?',
        //   zh: '投保人是否其記名司機？',
        //   type: 3,
        //   list: this.yesOrNoOption,
        //   key: 'policy_holder_is_driver',
        //   val:this.getOptionName('yesOrNoOption',policy_holder_is_driver)
        //
        // },
        // {
        //   en: 'Relationship with Policyholder',
        //   zh: '與投保人關係',
        //   type: 2,
        //   key:'relationship_id',
        //   list:this.globalOptions.relationship,
        //   disabled:policy_holder_is_driver==1,
        //   val:this.getOptionName('globalOptions.relationship',driver.relationship_id)
        // },
        // {
        //   en: 'Title',
        //   zh: '稱謂',
        //   type: 3,
        //   list: this.titleOption,
        //   key:'title',
        //   disabled:policy_holder_is_driver==1,
        //   val:this.getOptionName('titleOption',driver.title)
        //
        // },
        // {
        //   en: 'Last Name',
        //   zh: '姓氏',
        //   type: 1,
        //   key:'last_name',
        //   disabled:policy_holder_is_driver==1,
        //   val:driver.last_name
        // },
        // {
        //   en: 'First Name',
        //   zh: '名字',
        //   type: 1,
        //   key:'first_name',
        //   disabled:policy_holder_is_driver==1,
        //   val:driver.first_name
        // },
        // {
        //   en: 'Date of Birth',
        //   zh: '出生日期',
        //   type: 4,
        //   key:'birth',
        //   disabled:policy_holder_is_driver==1,
        //   val:driver.birth
        // },
        // {
        //   en: 'HKID Number',
        //   zh: '香港身份證號碼',
        //   en_tip: 'If the HKID Number is A123456(3), please input A1234563.\n',
        //   zh_tip: '如香港身份證號碼為 A123456(3)，只須輸入 A1234563。',
        //   type: 1,
        //   key:'id_no',
        //   disabled:policy_holder_is_driver==1,
        //   val:driver.id_no,
        //   showMessage:true
        // },
        // {
        //   en: 'Occupation',
        //   zh: '職業',
        //   type: 2,
        //   list: this.globalOptions.occ,
        //   key:'occ_id',
        //   disabled:policy_holder_is_driver==1,
        //   val:this.getOptionName('globalOptions.occ',driver.occ_id)
        // },
        {
          en: 'Type of Motorcycle Driving licence',
          zh: '電單車駕駛執照類型',
          type: 2,
          key:'driving_licence_id',
          list: this.globalOptions.driving_licence,
          val:this.getOptionName('globalOptions.driving_licence',driver.driving_licence_id)

        },
        // {
        //   en: 'Are all the driver(s) has not been involved in a motor accident in the past 3 years?',
        //   zh: '是否所有記名司機均沒有於過往三年內牽涉交通意外？',
        //   type: 3,
        //   list: this.accidentsOption,
        //   key:'has_accident',
        //   val:this.getOptionName('accidentsOption',driver.has_accident)
        // },
        // {
        //   en: 'Number of accident in last 3 years',
        //   zh: '過往3年內牽涉交通意外次數',
        //   type: 2,
        //   list: this.globalOptions.accidents,
        //   key:'accidents',
        //   noshow:driver.has_accident!=1,
        //   val:this.getOptionName('globalOptions.accidents',driver.accidents)
        //
        // },
        {
          en: 'Are all the driver(s) has not been deducted driving offence points in the past 3 years?',
          zh: '是否所有記名司機均沒有於過往三年內被扣分？',
          type: 3,
          list: this.deductedOption,
          key:'is_deducted_point',
          val:this.getOptionName('deductedOption',driver.is_deducted_point)

        },
        {
          en: 'Number of driving offence point in last 3 years',
          zh: '過往3年內被扣分數',
          type: 2,
          list: this.globalOptions.deducted_points,
          key:'deducted_points',
          nameKey:'name',
          noshow:driver.is_deducted_point!=2,
          val:this.getOptionName('globalOptions.deducted_points',driver.deducted_points)

        },

      ]
    },
    relationship_arr(){
      return (index)=>{
        let {client_type} = this.quoteInformationForm||{} //1 個人 2 公司

        let {relationship} = this.globalOptions||{}
        let relationship_arr = relationship.filter(item=>item.identity==client_type)
        if(this.isOneOfDriver&&index!=0){
          relationship_arr = relationship_arr.filter(item=>item.id!=8)
        }
        return relationship_arr
      }
    },
    driverFormList(index) {
      //type 1:input 2:select 3 radio 4.date
      let {policy_holder_is_driver} = this.formData||{}
      let {client_type} = this.quoteInformationForm||{} //1 個人 2 公司
      let driverList = this.formData.driver||[]

      let driver_obj =
        {
          first_name:'',
          last_name:'',
          title:'',
          occ_id:'',
          birth:'',
          id_no:'',
          id_type:'',
          relationship_id:'',
          driving_licence_id:'',
          has_accident:1,
          accidents:'',
          is_deducted_point:'',
          deducted_points:'',
        }
      if(driverList.length<=0)
        driverList = [...driver_obj]
      console.log('driverList--',driverList)
      let driver = driverList[0]||{}
      let isPerson = client_type ==1
      let {relationship} = this.globalOptions||{}
      let relationship_arr = relationship.filter(item=>item.identity==client_type)
      return [
        // {
        //   en: 'Policyholder is one of the Named Driver ?',
        //   zh: '投保人是否其記名司機？',
        //   type: 3,
        //   list: this.yesOrNoOption,
        //   disabled:true,
        //   noshow: !isPerson,
        //   key: 'policy_holder_is_driver',
        //   val:this.getOptionName('yesOrNoOption',policy_holder_is_driver)
        //
        // },
        // {
        //   en: 'Are all the driver(s) has not been involved in a motor accident in the past 3 years?',
        //   zh: '是否所有記名司機均沒有於過往三年內牽涉交通意外？',
        //   type: 3,
        //   list: this.accidentsOption,
        //   key:'has_accident',
        //   disabled:true,
        //   val:this.getOptionName('accidentsOption',driver.has_accident)
        // },
        // {
        //   en: 'Number of accident in last 3 years',
        //   zh: '過往3年內牽涉交通意外次數',
        //   type: 2,
        //   list: this.globalOptions.accidents,
        //   key:'accidents',
        //   noshow:driver.has_accident==1,
        //   val:this.getOptionName('globalOptions.accidents',driver.accidents)
        //
        // },
        {
          en: 'Relationship with Policyholder',
          zh: '與投保人關係',
          type: 2,
          key:'relationship_id',
          noshow: isPerson,
          list:relationship_arr,
          // disabled:policy_holder_is_driver==1,
          val:this.getOptionName('globalOptions.relationship',driver.relationship_id)
        },
        {
          en: 'Title',
          zh: '稱謂',
          type: 3,
          list: this.titleOption,
          key:'title',
          noshow: isPerson,
          // disabled:policy_holder_is_driver==1,
          val:this.getOptionName('titleOption',driver.title)

        },
        {
          en: 'Last Name',
          zh: '姓氏',
          type: 1,
          key:'last_name',
          noshow: isPerson,
          // disabled:policy_holder_is_driver==1,
          val:driver.last_name
        },
        {
          en: 'First Name',
          zh: '名字',
          type: 1,
          key:'first_name',
          noshow: isPerson,
          // disabled:policy_holder_is_driver==1,
          val:driver.first_name
        },
        {
          en: 'Date of Birth',
          zh: '出生日期',
          type: 4,
          key:'birth',
          // disabled:policy_holder_is_driver==1,
          noshow: isPerson,
          val:driver.birth
        },
        {
          en: driver.id_type==1?'HKID Number':'Passport Number',
          zh:  driver.id_type==1?'香港身份證號碼':'護照號碼',
          // en_tip: 'If the HKID Number is A123456(3), please input A1234563.\n',
          // zh_tip: '如香港身份證號碼為 A123456(3)，只須輸入 A1234563。',
          type: 5,
          key:driver.id_type==1?'id_no':'pp_no',
          // disabled:policy_holder_is_driver==1,
          noshow: isPerson,
          val:driver.id_type==1?driver.id_no:driver.pp_no,
          showMessage:true
        },
        {
          en: 'Occupation',
          zh: '職業',
          type: 2,
          list: this.globalOptions.occ,
          key:'occ_id',
          labelKey:'name1',
          // disabled:policy_holder_is_driver==1,
          noshow: isPerson,
          val:this.getOptionName('globalOptions.occ',driver.occ_id)
        },
        //   DRIVER NO
        // {
        //   en: 'Type of Motorcycle Driving licence',
        //   zh: '電單車駕駛執照類型',
        //   type: 2,
        //   key:'driving_licence_id',
        //   list: this.globalOptions.driving_licence,
        //   val:this.getOptionName('globalOptions.driving_licence',driver.driving_licence_id)
        //
        // },
        //
        // {
        //   en: 'Are all the driver(s) has not been deducted driving offence points in the past 3 years?',
        //   zh: '是否所有記名司機均沒有於過往三年內被扣分？',
        //   type: 3,
        //   list: this.deductedOption,
        //   key:'is_deducted_point',
        //   val:this.getOptionName('deductedOption',driver.is_deducted_point)
        //
        // },
        // {
        //   en: 'Number of driving offence point in last 3 years',
        //   zh: '過往3年內被扣分數',
        //   type: 2,
        //   list: this.globalOptions.deducted_points,
        //   key:'deducted_points',
        //   noshow:driver.is_deducted_point==1,
        //   val:this.getOptionName('globalOptions.deducted_points',driver.deducted_points)
        //
        // },

      ]
    },
    uploadFormList() {
      let {coverage={},vehicle_detail={},client_type,policy_holder_id_type} = this.quoteInformationForm||{}
      let {ncd} = vehicle_detail||{}
      return [
        {
          en: policy_holder_id_type==1?'HKID Number':'Passport Number',
          zh: policy_holder_id_type==1?'香港身份證號碼':'護照號碼',
          type: 5,
          noshow: client_type==2,
          key:'hkid_file_id',
          file:'hkid_file',
        },
        {
          en: 'CI',
          zh: '公司註冊證書',
          type: 5,
          noshow: client_type==1,
          key:'ci_file_id',
          file:'ci_file',
        },
        {
          en: 'BR',
          zh: '商業登記證',
          type: 5,
          noshow: client_type==1,
          key:'br_file_id',
          file:'br_file',
        },
        {
          en: 'Vehicle Registration Document',
          zh: '車輛登記文件 (牌簿)',
          type: 5,
          key:'vrd_file_id',
          file:'vrd_file',
        },
        {
          en: 'NCB proof (e.g. Renewal Notice)',
          zh: '無索償折扣證明 (例如: 續保通知書)',
          noshow:ncd==0,
          type: 5,
          key:'ncb_file_id',
          file:'ncb_file',
        },
        {
          en: 'Proof of Address (within 3 months)',
          zh: '住址證明 （3個月內）',
          type: 5,
          key:'proof_of_address_file_id',
          file:'proof_of_address_file',
        },
        // {
        //   en: 'Driver HKID Card / Passport',
        //   zh: '司機香港身份證/護照',
        //   type: 5,
        //   noshow: client_type==1,
        //   key:'hkid_file_id',
        //   file:'hkid_file',
        // },
        // {
        //   en: 'Driving Licence',
        //   zh: '駕駛執照',
        //   type: 5,
        //   key:'driving_licence_file_id',
        //   file:'driving_licence_file',
        // },

      ]

    },
    driverList(){
      let {driver=[]} = this.quoteInformationForm||{}
      let arr = driver.reduce((res,item)=>{
        let {accidents_file=[],accidents_file_id=[]} = item||{}
        let len = accidents_file&&accidents_file.length||0
        let len_id = accidents_file_id&&accidents_file_id.length||0
        let new_file = accidents_file||[]
        let new_file_id = accidents_file_id||[]
        if(len<3){
          for(var i=len;i<3;i++)
          {
            new_file.push({url:''})

          }
        }
        if(len_id<3){
          for(var i=len_id;i<3;i++)
          {
            new_file_id.push('')
          }
        }
        res.push({...item,accidents_file:new_file,accidents_file_id:new_file_id})
        return res
      },[])
      return arr
    },
    driverUploadFormList() {
      let {coverage={},driver=[],client_type,policy_holder_is_driver} = this.quoteInformationForm||{}
      let {ncd} = vehicle_detail||{}
      return [
        {
          en: 'Driver HKID Card / Passport',
          zh: '司機香港身份證/護照',
          type: 5,
          noshow: policy_holder_is_driver==1,
          key:'hkid_file_id',
          file:'hkid_file',
        },
        {
          en: 'Driving Licence',
          zh: '駕駛執照',
          type: 5,
          key:'driving_licence_file_id',
          file:'driving_licence_file',
        },

      ]

    },
    infoList() {
      let {coverage={},vehicle_detail={},client_type} = this.quoteInformationForm||{}
      let policyholder = client_type==1?this.policyholderFormList:this.policyholderFormCompanyList
      let arr = [
        {
          title_en: 'Policy Details',
          title_zh: '保單資料',
          list: this.policyForm,
          path:'/quoting/policy/details'
        },
        {
          title_en: 'Insured Motorcycle Details',
          title_zh: '電單車資料',
          list: this.motorFormList,
          path:'/quoting/insure/motor/form'
        },
        {
          title_en: 'Policyholder Details',
          title_zh: '投保人資料',
          list: {
            ...this.policyholderList,
            ...policyholder
          },
          path:'/quoting/insure/policyholder'
        },

      ]
      // if(client_type==2){
      //   arr.push(
      //     {
      //       title_en: 'Named Driver Information',
      //       title_zh: '記名司機資料',
      //       list: this.driverFormList,
      //       path:'/quoting/insure/policyholder'
      //     }
      //   )
      // }
      return arr
    }
  },
  mounted(){
    window.scrollTo({top: 0, behavior:'smooth'})
  },
  watch:{
  },
  methods: {
    ...mapActions('quotation',['setQuoteInformationForm','setQuotationData']),
    handleNext(isShowPop=false){
      this.$refs.form.validate((valid, rules) => {
        if(valid){
          let {vehicle_detail={},driver=[{}],client_type} = this.quoteInformationForm||{}
          let {policy_holder_is_driver} = this.formData||{}
          if(isShowPop&&client_type&&policy_holder_is_driver==2)
          {
            this.$ConfirmBox({
              message_en: 'If the policyholder is an individual, the policyholder is the principal driver of the vehicle',
              message: '如車輛以個人名義投保，投保人是主要用車司機',
              isCancel:false,
              confirmBtn:'OK',
            }).then(() => {
              // confirm
              // this.formData.policy_holder_is_driver = 1
              // this.handleSaveData()
            })
              .catch(() => {
                // cancel
              })
          }else this.handleSaveData()

        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    handleSaveData(){
      let {vehicle_detail={},driver=[{}],client_type} = this.quoteInformationForm||{}
      let {policy_holder_is_driver} = this.formData||{}
      let ve_detail = this.formData.vehicle_detail||{}
      let driver_detail = this.formData.driver||[]
      let driver_arr = driver_detail?driver_detail[0]:''
      this.formData.vehicle_detail = {
        ...vehicle_detail,
        ...ve_detail,
      }
      if(driver_detail.length>0)
      {
        this.$set(this.formData.driver,0,{
          ...driver[0],
          ...driver_arr,
        })
      }

      this.setQuoteInformationForm({
        ...this.quoteInformationForm,
        ...this.formData,
      })
      this.$router.push(this.nextPath)
      window.scrollTo({top: 0, behavior:'smooth'})
    },
    mixinNetwork(){
      return{
        getOrderReferralList:async (params,isReferralCode =false)=>{

        },
      }
    }
  }
}
